<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("studentGroups.title") }}
          </template>
          <template v-slot:toolbar>
            <b-button @click="newGroup()">{{ $t("common.new") }}</b-button>
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-4">
                  <b-form-group id="input-group-3" label-for="input-3">
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        id="input-3"
                        v-model="filter.educationYear"
                        :options="educationYears"
                        required
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group id="input-group-2" label-for="input-2">
                    <template v-slot:label>
                      {{ $t("studentGroups.list.level") }}
                    </template>
                    <b-form-select
                        id="input-2"
                        v-model="filter.level"
                        :options="levels"
                        required
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <b-button type="button" @click="reloadData" variant="primary">{{
                  $t("common.filter")           
                }}
              </b-button>
            </b-form>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th class="text-left">
                  {{ $t("studentGroups.list.name") }}
                </th>
                <th class="text-left">
                  {{ $t("studentGroups.list.level") }}
                </th>
                <th class="text-left">
                  {{ $t("studentGroups.list.year") }}
                </th>
                <th class="text-left">
                  {{ $t("studentGroups.list.classroomTeacher") }}
                </th>
                <th class="text-left">
                  {{ $t("studentGroups.list.shift") }}
                </th>    
                <th class="text-left">
                  Расписание звонков
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in groups" :key="item.id">
                <td>{{ item.groupName }}</td>
                <td>{{ item.level }}</td>
                <td>{{ item.educationYearString }}</td>
                <td>{{ item.classroomTeacherName }}</td>
                <td>{{ shiftVal(item.shift) }}</td>
                <td>{{ item.callScheduleName }}</td>
                <td>
<!--                  <b-button
                      type="submit"
                      @click="editGroup(item)"
                      variant="primary"
                  >
                    {{ $t("common.edit") }}
                  </b-button>
                  <b-button @click="openStudents(item)" variant="primary">
                    {{ $t("studentsList.title") }}
                  </b-button>
                  <b-button
                      @click="openGroupSubjects(item)"
                      variant="primary"
                  >
                    {{ $t("groupSubjects.title") }}
                  </b-button>
                  <b-button @click="openTimeTable(item)" variant="primary">
                    {{ $t("timeTable.title") }}
                  </b-button>
                  <b-button @click="openGradeBook(item)" variant="primary">
                    {{ $t("gradeBook.title") }}
                  </b-button>-->
                  <div class="dropdown dropdown-inline mr-4">
                    <button class="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                      ...
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" href="#" @click="editGroup(item)">{{ $t("common.edit") }}</a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item" href="#" @click="openStudents(item)">{{ $t("studentsList.title") }}</a>
                      <a class="dropdown-item" href="#" @click="openGroupSubjects(item)">{{ $t("groupSubjects.title") }}</a>
                      <a class="dropdown-item" href="#" @click="openTimeTable(item)">{{ $t("timeTable.title") }}</a>
                      <a class="dropdown-item" href="#" @click="openGradeBook(item)">{{ $t("gradeBook.title") }}</a>
                    </div>
                  </div>
                  <div class="dropdown dropdown-inline mr-4">
                    <button class="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButtonReports"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                      Отчет
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" href="#" @click="openQuarterMarks(item, 1)">I четверть</a>
                      <a class="dropdown-item" href="#" @click="openQuarterMarks(item, 2)">II четверть</a>
                      <a class="dropdown-item" href="#" @click="openQuarterMarks(item, 3)">III четверть</a>
                      <a class="dropdown-item" href="#" @click="openQuarterMarks(item, 4)">IV четверть</a>
                      <a class="dropdown-item" href="#" @click="openQuarterMarks(item, 5)">Годовой</a>
                      <a class="dropdown-item" href="#" @click="showMoveForm(item)" v-if="showMoveButton(item)">Перевести на следующий учебный год</a>
                      <a class="dropdown-item" href="#" @click="openGroupInfo(item)">Детали</a>
                      <router-link :to="{ name: 'GroupJournalExport', params: { groupId: item.id }}" class="dropdown-item">
                        Журнал
                      </router-link>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div>
              <b-modal id="modal-1" ref="groups-modal">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <b-form v-if="show">
                  <b-form-group >
                    <template v-slot:label>
                      {{ $t("studentGroups.list.name") }}
                    </template>
                    <b-form-input
                        v-model="form.groupName"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.level") }}
                    </template>
                    <b-form-select
                        v-model="form.level"
                        :options="levels"
                        required
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        v-model="form.educationYear"
                        :options="educationYears"
                        required
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.classroomTeacher") }}
                    </template>
                    <b-form-select
                        v-model="form.classroomTeacherId"
                        :options="teachers"
                        required
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.shift") }}
                    </template>
                    <b-form-select
                        v-model="form.shift"
                        :options="shifts"
                        required
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      Расписание звонков
                    </template>
                    <b-form-select
                        v-model="form.callScheduleId"
                        :options="callSchedules"
                    ></b-form-select>
                  </b-form-group> 
                  <b-form-group>
                    <template v-slot:label>
                      Язык обучения
                    </template>
                    <b-form-select
                        v-model="form.languageOfInstructionId"
                        :options="languageOfInstructions"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox v-model="form.allowNoRatings">
                      Разрешить не ставить оценки
                    </b-form-checkbox>
                  </b-form-group>   
                  <b-form-group>
                    <b-form-checkbox
                        v-model="form.allowNoWriteLessonContent"
                    >Разрешить не писать тему уроков
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                        v-model="form.allowNoWriteHomeWork"
                    >
                      Разрешить не писать домашнее задание
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                        v-model="form.allowTopicsFilledInAdvance"
                    >
                      Разрешить наперед писать темы
                    </b-form-checkbox>
                  </b-form-group>   
                  <b-form-group>
                    <template v-slot:label>
                      Миниммальное количество оценок за день
                    </template>
                    <b-form-input
                        v-model="form.minMarksAmountToUnlockNextLesson"
                    >                      
                    </b-form-input>
                  </b-form-group>      
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary">{{
                      $t("common.save")
                    }}
                  </b-button>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                      $t("common.cancel")
                    }}
                  </b-button>
                </template>
              </b-modal>
              <b-modal id="move-modal" ref="move-modal">
                <template v-slot:modal-title>
                  {{ $t("studentGroups.moveToNextYear") }} - {{moveGroup.currentName}}
                </template>
                <b-form>
                  <b-form-group >
                    <template v-slot:label>
                      {{ $t("studentGroups.move.newGroupName") }}
                    </template>
                    <b-form-input
                        v-model="moveGroup.newGroupName"
                        type="email"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="moveToNextYear" variant="primary" id="move-to-next-year-confirm-button">{{
                      $t("studentGroups.moveToNextYear")
                    }}
                  </b-button>
                  <b-button type="reset" @click="closeMoveForm" variant="danger">{{
                      $t("common.cancel")
                    }}
                  </b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "schoolAdminStudentGroups",
  components: {
    KTPortlet
  },
  data() {
    return {
      filter:{
        educationYear: DictionariesService.currentYear(),
        level: 1
      },
      schoolId: null,
      groups: null,
      dialog: true,
      form: {
        groupName: "",
        level: "",
        educationYear: "",
        classroomTeacherId: "",
        callScheduleId: 0,
        allowNoRatings: false,
        allowNoWriteLessonContent: false,
        allowNoWriteHomeWork: false,
        minMarksAmountToUnlockNextLesson: false,
      },
      show: true,
      levels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      teachers: [],
      educationYears: DictionariesService.educationYearOptions(),
      shifts: [{text: "Первая", value: 1}, {text: "Вторая", value: 2}, { text: "Третья", value: 3 }],
      currentEducationYear: DictionariesService.currentYear(),
      moveGroup: {},
      callSchedules: [],
      languageOfInstructions: [],
    };
  },
  mounted() {
    let filters = JSON.parse(localStorage.getItem("studentGroupFilters"));
    
    if(filters !== null){
      this.filter = filters;
    }
    
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      this.form.schoolId = this.$route.params.id;
      
      ApiService.postSecured("studentGroups/save", this.form)
          .then(function () {
            $this.$refs["groups-modal"].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            console.log(response);
            $this.reloadData();
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};
      this.$refs["groups-modal"].hide();
    },
    reloadData() {
      let $this = this;
      
      localStorage.setItem("studentGroupFilters", JSON.stringify(this.filter));
      ApiService.postSecured("studentGroups/list", this.filter).then(({data}) => {
        $this.groups = data.list;
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("dictionaries/teachersLoggedUserSchool", {}).then(({data}) => {
        $this.teachers = data.dictionary;
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("dictionaries/callSchedules")
          .then(({data}) => {
            $this.callSchedules = data.dictionary;
          }).catch(({response}) => {
        console.log(response);
      });  
      
      ApiService.querySecured("dictionaries/languageOfInstruction")
          .then(({data}) => {
            $this.languageOfInstructions = data.dictionary;
          }).catch(({response}) => {
        console.log(response);
      });
    },
    cleanModel() {
      this.form = {};
    },
    newGroup() {
      this.form = {};
      this.$refs["groups-modal"].show();
    },
    editGroup(group) {
      this.form = group;
      this.$refs["groups-modal"].show();
    },
    openStudents(group) {
      this.$router.push({name: "students", params: {id: group.id}});
    },
    openGroupSubjects(group) {
      this.$router.push({name: "groupSubjects", params: {id: group.id}});
    },
    openTimeTable(group) {
      this.$router.push({name: "timeTableForGroup", params: {id: group.id}});
    },
    openGradeBook(group) {
      this.$router.push({name: "groupGradeBook", params: {groupId: group.id}});
    },
    openQuarterMarks(group, quarter) {
      this.$router.push({name: "quarterMarks", params: {groupId: group.id, quarter: quarter}});
    },
    openGroupInfo(group) {
      this.$router.push({name: "groupInfo", params: {groupId: group.id}});
    },
    /*move*/
    showMoveButton(group){
      return group.educationYear !== this.currentEducationYear && group.isMovedToNextYear !== true && group.level < 11;
    },
    showMoveForm(group){
      this.moveGroup = {
        currentName: group.groupName,
        groupId: group.id,
        newGroupName: ""
      };
      
      this.$refs["move-modal"].show();
    },
    closeMoveForm(){
      this.$refs["move-modal"].hide();
      this.moveGroup = {};
    },
    moveToNextYear(evt){
      evt.preventDefault();
      let $this = this;
      let submitButton = document.getElementById('move-to-next-year-confirm-button');
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");
      
      ApiService.postSecured("studentGroups/moveGroupToNextYear", this.moveGroup)
          .catch(({response}) => {
        console.log(response);
      }).finally(function () {
        submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
        
        $this.$refs["move-modal"].hide();
        $this.reloadData();
      });
    },    
    shiftVal(shift) {
      let shiftVal = '';
      switch (shift) {
        case 1:
          shiftVal = 'Первая';
          break;
        case 2:
          shiftVal = 'Вторая';
          break;
        case 3:
          shiftVal = 'Третья';
          break;
      }

      return shiftVal;
    }
  }
};
</script>
